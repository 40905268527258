<template>
  <DsPageLayout
    :theme="theme"
    prevent-loading
  >
    <!-- <template #cookie-bar>
        <PageCookieBar />
      </template> -->
    <template #header="{ headerTheme }">
      <LayoutPageHeader
        v-if="navigationData"
        :header-theme="headerTheme"
        :logo-label="LOGO_LABEL_CAREERS"
        :navigation-data="navigationData"
        hide-search
        hide-loading
        logo-as-nuxt-link
      />
      <div
        id="portal-header"
        ref="headerPortal"
        class="header-portal"
        @change="handleHeaderPortalUpdate($event, headerPortal)"
      />
    </template>
    <template #content>
      <div
        :style="{ paddingTop: `${headerPortalHeight}px` }"
        class="ds-width-full"
      >
        <slot />
      </div>
    </template>
    <template #footer>
      <LayoutPageFooter
        v-if="footerNavigationData"
        :footer-data="footerNavigationData"
      />
    </template>
    <template #overlay>
      <div id="portal-overlay" />
    </template>
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-once
      v-html="noscriptHtml"
    />
    <!-- eslint-enable vue/no-v-html -->
  </DsPageLayout>
</template>

<script setup>
/**
 * Default Layout
 * will be used on pages where there is no custom layout set
 */

import { DsPageLayout } from 'awds'

const LOGO_LABEL_CAREERS = 'Careers'
const GREY_THEME_PAGES = ['career-types', 'faqs']

const headerPortal = ref()

const { handleHeaderPortalUpdate, headerPortalHeight, noscriptHtml } = useLayout()
const route = useRoute()
const { theme } = useTheme()
const { localeGlobalContentData } = useGlobalData()

const navigationData = computed(() => localeGlobalContentData.value?.globalNavigationData?.careers)
const footerNavigationData = computed(() => localeGlobalContentData.value?.globalFooterData?.careers)

watchEffect(() => {
  if (GREY_THEME_PAGES.some((slug) => route.name.includes(slug))) {
    theme.value = 'grey'
    return
  }

  theme.value = 'light'
  return 'light'
})
</script>
